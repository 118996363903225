<template>
  <b-card
    no-body
    :class="$store.state.appConfig.layout.skin === 'dark' ? 'border-dark card-employee-task' : 'card-employee-task'"
  >
    <b-card-header>
      <b-card-title>Students</b-card-title>
      <b-link class="font-weight-bold cursor-pointer text-primary" :to="{ name: 'students' }">
        View All
      </b-link>
      <!-- <span class="font-weight-bold cursor-pointer">
        View All
      </span> -->
    </b-card-header>

      <hr class="mt-0">
    <!-- body -->
    <b-card-body>
      
        <center v-if="error" >
          <h3>{{ error_msg }}</h3>
        </center>
        
        <center v-if="load" >
          <b-spinner  variant="primary" label="Spinning"></b-spinner>
        </center>
      <div 
        v-for="(student,index) in items.data"
        :key="index"
        class="employee-task cursor-pointer"
      >
        <div class="d-flex justify-content-between align-items-center" @click="student_analytics(student.id)">
          <b-media no-body>
            <b-media-aside class="mr-75">
              <b-avatar
                rounded
                size="42"
                :src="student.avatar"
              />
            </b-media-aside>
            <b-media-body class="my-auto">
              <h6 class="mb-0">
                {{ student.fullName }}
              </h6>
              <!-- <small>{{ employee.designation }}</small> -->
            </b-media-body>
          </b-media>
          <div class="d-flex align-items-center">
            <small class="text-dark mr-75 font-weight-bolder">{{ student.curriculum }}%</small>

            <!-- chart -->
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
            <!-- <vue-apex-charts
              type="radialBar"
              height="30"
              width="30"
              :options="chartData[index].options"
              :series="chartData[index].series"
            /> -->
          </div>
        </div>
        <hr>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody,BSpinner,BLink,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { mapGetters } from "vuex";
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    VueApexCharts,
    BSpinner,
    BLink,
  },
  computed: {
    ...mapGetters({
      items: "teachers/items",
      load: "teachers/load",
    }),
  },
  data() {
    return {
      error:false,
      error_msg:null,
      chartData: [],
      chartColor: [$themeColors.primary, $themeColors.danger, $themeColors.success, $themeColors.secondary, $themeColors.warning, $themeColors.primary],
      chartSeries: [45, 65, 60, 35, 65, 80],
      employeeData: [
        {
          avatar: require('@/assets/images/profile/avatars/Number=1.png'),
          userFullName: 'Ryan Harrington',
          designation: 'iOS Developer',
          duration: '89%',
        },
        {
          avatar: require('@/assets/images/profile/avatars/Number=11.png'),
          userFullName: 'Louisa Norton',
          designation: 'UI Designer',
          duration: '89%',
        },
        {
          avatar: require('@/assets/images/profile/avatars/Number=15.png'),
          userFullName: 'Jayden Duncan',
          designation: 'Java Developer',
          duration: '89%',
        },
        {
          avatar: require('@/assets/images/profile/avatars/Number=20.png'),
          userFullName: 'Cynthia Howell',
          designation: 'Anguler Developer',
          duration: '89%',
        },
        {
          avatar: require('@/assets/images/profile/avatars/Number=50.png'),
          userFullName: 'Helena Payne',
          designation: 'Marketing',
          duration: '89%',
        },
        {
          avatar: require('@/assets/images/profile/avatars/Number=100.png'),
          userFullName: 'Troy Jensen',
          designation: 'iOS Developer',
          duration: '89%',
        },
      ],
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%',
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
    }
  },
  methods:{
    student_analytics(id){
      this.$router.push({ name: 'student_analytics', params: { id: id } })
    }
  },
  mounted() {
    this.$store.dispatch("teachers/students",{query: {page: 1},}).then((_) => {
    }).catch((error)=>{
      this.error = true;
      this.error_msg = error.response.data.error;
      
    });
  },
  created() {
    for (let i = 0; i < this.employeeData.length; i += 1) {
      const chartClone = JSON.parse(JSON.stringify(this.chart))
      chartClone.options.colors[0] = this.chartColor[i]
      chartClone.series[0] = this.chartSeries[i]
      this.chartData.push(chartClone)
    }
  },
}
/* eslint-disable global-require */
</script>
